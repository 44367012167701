import React from 'react'
import About from '../Components/About'

function AboutPage() {
  return (
    <div>
      <About />
    </div>
  )
}

export default AboutPage