import React from 'react';
import './Project.css';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Project({ name, tech, image, description, link }) {
  return (
    <div className='project'>
      <div className="image__container">
        <img className='image' src= { image } alt="" />
      </div>
      <div className="project__desc">
        <h1>{ name }</h1>
        <h3>The tech used: <span className='red'>{ tech }</span></h3>
        <p>{ description }</p>
        <Link className='btn btn-one' target='_blank' to={ link }>
          View Live! <FontAwesomeIcon icon="fa-solid fa-arrow-up-right-from-square" />
        </Link>
      </div>
    </div>
  )
};

export default Project;