import React from 'react';
import './About.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import target from '../Assets/target.svg';
import coding from '../Assets/coding.svg';

AOS.init();

function About() {
  return (
    <div className='about'>
      <div className="about__container" >
        <div data-aos='fade-up' data-aos-delay='150' className="box">
          <h1 className='red'>The beginning</h1>
          <p>
            My journey in Software Development began in year 8 at my secondary school when I 
            made a small endless platform game on Scratch. This ignited my curiosity, leading me 
            to pursue Computer Science for GCSE and A-level. During these years I learnt Python 
            and several Software Development Methodologies as well as tackling multiple 
            coursework, including a big project at the end of A-level. This coursework used the 
            library pygame and it was a penalty-shootout game where 2 players would play against 
            each-other to try and score as many goals as they could ultimately when a player had 
            an advantage of 2 goals, that player would win.
          </p> 
        </div>
        <div data-aos='fade-left' data-aos-duration='2000' className='img__container'>
          <img className ='img' src={ coding } alt="Coding" /> 
        </div>
      </div>
      <div data-aos='fade-up' data-aos-delay="200" className='about__container--mission' style={{margin: '72px 0'}}>
        <h1 className='red'>The Mission Now</h1> 
        <div className='box--mission'>
    <     div data-aos='fade-right' data-aos-duration='2000' className='img__container'>
            <img  className ='img' src={ target } alt="Target" />
          </div>
          <div className="box"> 
            <p>
              These days it's a little more complicated! I now want to contribute to products that have an effect on
              peoples lives. 
              Whether it’s helping a business create it's first website or enhancing the User Interfaces of a current website,
              I’m driven by the belief that it can improve someone, somewhere.
              It’s not just about algorithms; it’s about empathy, understanding user needs, and crafting solutions that resonate beyond the screen.
            </p>
            <p style={{marginTop:'16px'}}>
              What once was a hobby during my teenage years, has now turned into my career.  As a Software Developer,
              I want to unleash my creativity and problem-solving skills to enhance the lives of individual’s and business’ alike
              and I’m excited to continue shaping the digital landscape with my passion and expertise.
            </p>   
          </div>
        </div>
        
        
      </div>
    </div>
  )
}

export default About;