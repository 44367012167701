import React from 'react';
import Landing from '../Components/Landing';
import About from '../Components/About';
import Tech from '../Components/Tech';
import Projects from '../Components/Projects';

function MainPage() {
  return (
    <>
      <Landing />
      <Tech />
      <Projects />
    </>
  )
}

export default MainPage;