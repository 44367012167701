import React from 'react';
import './TechBox.css'

function TechBox({ name }) {
  return (
    <div className='tech__box'>
      <p> { name } </p>
    </div>
  )
}

export default TechBox;