import React, { useState } from 'react';
import logo from '../Assets/logo.png';
import '../Components/Nav.css';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Nav() {
  function goTo(location) {
    setTimeout(() =>{
      if(location === 'tech') {
        window.scrollBy(0,920);
      }
      else {
        window.scrollBy(0,1450);
      }
    },50)
  }

  return (
    <div className='nav'>
      <a href='/'>
        <img className='logo' src= { logo } alt= "LPSF" />
      </a>
      <ul className="menu1">
        <li><Link to='/' onClick={() => goTo('tech')} className='nav__link to-remove'> TECH </Link></li>
        <li><Link to='/' onClick={() => goTo('projects')} className='nav__link to-remove'> PROJECTS </Link></li>
        <li><Link className='nav__link' to='/about'> ABOUT </Link></li>
      </ul>
    </div>
  )
}

export default Nav;