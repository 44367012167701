import React from 'react';
import './Projects.css'
import Project from './UI/Project';
import Ultraverse from '../Assets/Ultraverse.png';
import Twitter from '../Assets/twitter.png';
import Book from '../Assets/Book.png';
import Portfolio from '../Assets/Portfolio.png';
import AOS from 'aos';
import 'aos/dist/aos.css';

AOS.init();

function Projects() {
  return (
    <div className='projects new__section'>
      <h1 className='title'>And the even more important stuff...</h1>
      <div className="project__list">
        <div data-aos='fade-up' data-aos-duration='1000'>
          <Project 
            name='Graphic Designer Portfolio' 
            tech='React, CSS, JS' 
            image= { Portfolio } 
            link='https://tristen-hanson.vercel.app/' 
            description= 'A portfolio for a graphic designer. Designed and built to their taste and requirements from the ground up.' 
          />
        </div>
        <div data-aos='fade-up' data-aos-duration='1000'>
          <Project 
            name='Ultraverse' 
            tech='React, CSS, JS' 
            image= { Ultraverse } 
            link='https://luis-internship-lpedrosf.vercel.app/' 
            description= 'Ultraverse is part of an open-source project of an NFT marketplace, connected to an API to gather all data from authors to NFTs.' 
          />
        </div>
        <div data-aos='fade-up' data-aos-duration='1000'>
          <Project 
            name='Twitter-clone' 
            tech='React, CSS, JS, Firebase' 
            image= { Twitter } 
            link='https://twitter-clone-rouge-seven.vercel.app/' 
            description= 'A clone of twitter, connected to firebase to store all tweets made. Go leave a nice message and an image!' 
          />
        </div>
        <div data-aos='fade-up' data-aos-duration='1000'>
          <Project 
            name='Book Library' 
            tech='React, CSS, JS, Json' 
            image= { Book }
            link='https://book-library-hazel.vercel.app/' 
            description= 'A online library of books using a .json file to get all data from the books.' 
          />
        </div>
      </div>
    </div>
  )
}

export default Projects