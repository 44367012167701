import React from 'react';
import '../Components/Landing.css';
import { Link } from 'react-router-dom';
import resume from '../Assets/LuisFaria.pdf';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Contacts from './Contacts';
import AOS from 'aos';
import 'aos/dist/aos.css';

AOS.init();

function Landing() {
  function goNext() {
    window.scrollBy(0,920);
  }
  
  return (
    <div className="light">
      <div className='landing'>  
        <div className="landing__text">
          <h1 data-aos='fade-left' >Welcome,</h1>
          <h1 data-aos='fade-left' data-aos-delay="300" data-aos-duration='700'>I'm <span className='red'> Luis Faria</span>!</h1>
          <h2 data-aos='fade-left' data-aos-delay="600" data-aos-duration='700' className="landing__subtitle">I'm a<span className='red'> Software Developer </span> specialised in React, CSS, JavaScript and Python.</h2>
          <Link data-aos='fade' data-aos-duration='1000' data-aos-delay="1100" className='link' target='_blank' to={ resume }>
            <button className="landing__button">Get my resume</button>
          </Link>
        </div>
      </div>
      <div className="arrow__container">
        <button className='arrow red' onClick={() => goNext()}>
          <FontAwesomeIcon  icon="fa-solid fa-arrow-down" />
        </button>  
      </div>
      <Contacts />
    </div>
  )
}

export default Landing;